import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Home,
  Zap,
  Recycle,
  Plane,
  Car,
  Smartphone,
  Mail,
  ShoppingBag,
  ArrowLeft,
  ArrowRight,
  Leaf,
} from "lucide-react";
import { IoFastFoodOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import "./Question1.css";

const questions = [
  // Household Information
  {
    id: 1,
    type: "slider",
    question: "How many people live in your household?",
    title: "Household Information",
    min: 1,
    max: 10,
    icon: Home,
  },
  {
    id: 2,
    type: "number",
    question: "What is the total square footage of your home?",
    title: "Household Information",
    icon: Home,
  },

  // Energy Consumption
  {
    id: 3,
    type: "number",
    question: "What is your average monthly electricity usage (in units)",
    title: "Energy Consumption",
    icon: Zap,
  },
  {
    id: 4,
    type: "radio",
    question:
      "How often do you use electric appliances for more than 3 hours a day?",
    title: "Energy Consumption",
    options: ["Daily", "Weekly", "Monthly", "Rarely"],
    icon: Zap,
  },

  // Travel Habits
  {
    id: 5,
    type: "radio",
    question:
      "How often do you travel by car for long distances (over 100 Km)?",
    title: "Travel Habits",
    options: ["Once a month", "Every 3 months", "Once a year", "Never"],
    icon: Car,
  },
  {
    id: 6,
    type: "slider",
    question: "How many Km do you drive per week?",
    title: "Travel Habits",
    min: 0,
    max: 500,
    step: 10,
    icon: Car,
  },

  // Food Consumption
  {
    id: 7,
    type: "radio",
    question: "How often do you consume meat or animal products in a week?",
    title: "Food Consumption",
    options: ["Not Applicable", "Most Meals", "Some Meals", "Rarely"],
    icon: IoFastFoodOutline,
  },
  {
    id: 8,
    type: "radio",
    question: "How often do you consume locally-sourced or organic food?",
    title: "Food Consumption",
    options: ["Always", "Often", "Rarely", "Never"],
    icon: IoFastFoodOutline,
  },

  // Digital Device Usage
  {
    id: 9,
    type: "number",
    question: "How many hours per day do you spend on electronic devices?",
    title: "Digital Device Usage",
    icon: Smartphone,
  },
  {
    id: 10,
    type: "number",
    question: "How many mails do you send daily?",
    title: "Digital Device Usage",
    icon: Smartphone,
  },

  // Waste Management
  {
    id: 11,
    type: "radio",
    question: "How often do you recycle household waste?",
    title: "Waste Management",
    options: ["Always", "Sometimes", "Rarely", "Never"],
    icon: Recycle,
  },
  {
    id: 12,
    type: "number",
    question: "How many Kg's of waste do you generate per day?",
    title: "Waste Management",
    icon: Recycle,
  },

  // Shopping Habits
  {
    id: 13,
    type: "radio",
    question: "How often do you buy new items (clothing, gadgets, etc.)?",
    title: "Shopping Habits",
    options: ["Monthly", "Every 3 months", "Once a year", "Rarely"],
    icon: ShoppingBag,
  },
  {
    id: 14,
    type: "radio",
    question: "Do you prefer buying second-hand or eco-friendly products?",
    title: "Shopping Habits",
    options: ["Always", "Sometimes", "Never"],
    icon: ShoppingBag,
  },

  // Recycling Habits
  {
    id: 15,
    type: "radio",
    question:
      "Do you separate recyclables (plastic, paper, metal) from your regular waste?",
    title: "Recycling Habits",
    options: ["Yes", "No"],
    icon: Recycle,
  },
  {
    id: 16,
    type: "slider",
    question: "How much of your plastic waste do you recycle each month?",
    title: "Recycling Habits",
    min: 0,
    max: 100,
    step: 5,
    icon: Recycle,
  },
];

const QuestionInput = ({ question, value, onChange }) => {
  switch (question.type) {
    case "slider":
      return (
        <div className="slider-container">
          <label htmlFor={`slider-${question.id}`} className="slider-label">
            {question.min} - {question.max}
          </label>
          <input
            id={`slider-${question.id}`}
            type="range"
            min={question.min}
            max={question.max}
            step={question.step || 1}
            value={value || question.min}
            onChange={(e) => onChange(parseInt(e.target.value))}
            className="slider"
          />
          <span className="slider-value">{value || question.min}</span>
        </div>
      );
    case "number":
      return (
        <div className="number-input-container">
          <input
            type="number"
            value={value || ""}
            onChange={(e) => onChange(parseInt(e.target.value))}
            className="number-input"
            placeholder={`Enter ${question.question.toLowerCase()}`} // Placeholder for number input
          />
        </div>
      );
    case "checkbox":
      return (
        <div className="checkbox-group">
          {question.options.map((option) => (
            <div key={option} className="checkbox-item">
              <input
                type="checkbox"
                id={option}
                checked={value && value.includes(option)}
                onChange={(e) => {
                  const newValue = e.target.checked
                    ? [...(value || []), option]
                    : (value || []).filter((item) => item !== option);
                  onChange(newValue);
                }}
              />
              <label htmlFor={option}>{option}</label>
            </div>
          ))}
        </div>
      );
    case "radio":
      return (
        <div className="radio-group">
          {question.options.map((option) => (
            <div key={option} className="radio-item">
              <input
                type="radio"
                id={option}
                value={option}
                checked={value === option}
                onChange={() => onChange(option)}
              />
              <label htmlFor={option}>{option}</label>
            </div>
          ))}
        </div>
      );
    default:
      return null;
  }
};

const CarbonFootprintCalculator = () => {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const savedAnswers = localStorage.getItem("carbonFootprintAnswers");
    if (savedAnswers) {
      setAnswers(JSON.parse(savedAnswers));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("carbonFootprintAnswers", JSON.stringify(answers));
  }, [answers]);

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      navigate("/results", { state: { answers, userName } });
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleInputChange = (value) => {
    setAnswers({ ...answers, [questions[currentQuestion].id]: value });
  };

  const question = questions[currentQuestion];
  const progress = ((currentQuestion + 1) / questions.length) * 100;

  return (
    <div className="question-wholepage">
      <Navbar />
      <div className="calculator-container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="calculator-card"
        >
          <h1 className="calculator-title">{question.title}</h1>
          <div className="progress-bar">
            <div
              className="progress-fill"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <AnimatePresence mode="wait">
            <motion.div
              key={currentQuestion}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              className="question-container"
            >
              <div className="question-header">
                {React.createElement(question.icon, {
                  className: "question-icon",
                })}
                <h2 className="question-text">{question.question}</h2>
              </div>
              <QuestionInput
                question={question}
                value={answers[question.id]}
                onChange={handleInputChange}
              />
            </motion.div>
          </AnimatePresence>
          <div className="button-container">
            <button
              onClick={handlePrevious}
              disabled={currentQuestion === 0}
              className="button button-outline"
            >
              <ArrowLeft className="button-icon" />
              Previous
            </button>
            <button
              onClick={handleNext}
              disabled={
                currentQuestion === questions.length && !answers[question.id]
              }
              className="button button-primary"
            >
              {currentQuestion === questions.length - 1 ? "Finish" : "Next"}
              <ArrowRight className="button-icon" />
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default CarbonFootprintCalculator;
