import React from "react";
import {
  Leaf,
  Calculator,
  Globe,
  ThermometerSun,
  Wind,
  Droplets,
  Sprout,
} from "lucide-react";
import "./HomePage.css";
import { useNavigate } from "react-router-dom";
import Image from "../assets/images/smoke.jpg";

const HomePage = () => {
  const navigate = useNavigate(); // Use the navigate hook
  const handleNext = () => {
    navigate("/country");
  };
  return (
    <div className="body-for-home">
      <div className="home-page">
        <header className="header">
          <div className="logo">
            <Leaf className="logo-icon" />
            <span>kyc.earth</span>
          </div>
          <nav>
            <a href="#about">About</a>
            <a href="#impact">Impact</a>
            <a href="#calculate">Calculate</a>
            <a href="#contact">Contact</a>
          </nav>
        </header>

        <section className="hero">
          <div className="hero-content">
            <h1>Understand Your Carbon Footprint</h1>
            <p>
              Discover your impact on the environment and learn how to reduce
              it.
            </p>
            <button className="cta-button" onClick={handleNext}>
              <Calculator className="cta-icon" />
              Calculate Now
            </button>
          </div>
          <div className="hero-image">
            <Sprout className="globe-icon" />
          </div>
        </section>

        <section className="about" id="about">
          <h2>What is Carbon Footprinting?</h2>
          <p>
            Carbon footprinting is the process of measuring the total greenhouse
            gas emissions caused directly and indirectly by an individual,
            organization, event, or product. It's expressed as carbon dioxide
            equivalent (CO2e) and helps us understand our impact on climate
            change.
          </p>
          <div className="info-cards">
            <div className="info-card">
              <ThermometerSun className="info-icon" />
              <h3>Climate Change</h3>
              <p>
                Carbon footprints contribute to global warming and climate
                change, affecting ecosystems worldwide.
              </p>
            </div>
            <div className="info-card">
              <Wind className="info-icon" />
              <h3>Air Quality</h3>
              <p>
                Higher carbon emissions lead to poor air quality, impacting
                human health and the environment.
              </p>
            </div>
            <div className="info-card">
              <Droplets className="info-icon" />
              <h3>Water Resources</h3>
              <p>
                Climate change affects water availability and quality,
                disrupting ecosystems and human activities.
              </p>
            </div>
          </div>
        </section>

        <section className="impact" id="impact">
          <h2>How Does It Affect Us?</h2>
          <div className="impact-content">
            <div className="impact-text">
              <p>
                Our carbon footprint has far-reaching consequences on both the
                environment and our daily lives:
              </p>
              <ul>
                <li>Rising global temperatures and extreme weather events</li>
                <li>Melting ice caps and rising sea levels</li>
                <li>Disruption of ecosystems and biodiversity loss</li>
                <li>Food and water scarcity</li>
                <li>
                  Economic impacts on agriculture, tourism, and infrastructure
                </li>
              </ul>
            </div>
            <div className="impact-image">
              <img
                src={Image}
                alt="Impact Visualization"
                className="impact-image-display"
              />
            </div>
          </div>
        </section>

        <section className="calculate" id="calculate">
          <h2>Ready to Calculate Your Carbon Footprint?</h2>
          <p>
            Take the first step towards a more sustainable lifestyle. Our
            easy-to-use calculator will help you understand your environmental
            impact and provide personalized recommendations for reducing your
            carbon footprint.
          </p>
          <button className="cta-button" onClick={handleNext}>
            <Calculator className="cta-icon" />
            Start Calculation
          </button>
        </section>

        <footer className="footer">
          <div className="footer-content">
            <div className="footer-logo">
              <Leaf className="logo-icon" />
              <span>kyc.earth</span>
            </div>
            <div className="footer-links">
              <a href="#about">About</a>
              <a href="#impact">Impact</a>
              <a href="#calculate">Calculate</a>
              <a href="#contact">Contact</a>
            </div>
          </div>
          <div className="footer-bottom">
            <p>&copy; 2024 kyc.earth. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default HomePage;
