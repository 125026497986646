import React, { useState } from "react";
import {
  Leaf,
  ChevronDown,
  LogOut,
  HelpCircle,
  Settings,
  User,
} from "lucide-react";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogout = () => {
    navigate("/Home-Page");
  };
  const handleHome = () => {
    navigate("/Home-Page");
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <a href="/" className="logo">
          <Leaf className="leaf-icon" />
          <span>kyc.earth</span>
        </a>
      </div>

      <div className="navbar-center">
        <div
          className="nav-dropdown"
          onMouseEnter={() => setIsDropdownOpen(true)}
          onMouseLeave={() => setIsDropdownOpen(false)}
        >
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <a href="/guides" className="dropdown-item">
                <HelpCircle className="dropdown-icon" />
                Guides
              </a>
              <a href="/tutorials" className="dropdown-item">
                <Settings className="dropdown-icon" />
                Tutorials
              </a>
              <a href="/blog" className="dropdown-item">
                <User className="dropdown-icon" />
                Blog
              </a>
            </div>
          )}
        </div>
      </div>

      <div className="navbar-right" onClick={handleLogout}>
        <a href="" className="nav-link">
          Home
        </a>

        <button className="exit-button" onClick={handleLogout}>
          <LogOut className="exit-icon" />
          <span>Exit</span>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
