import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { User, MapPin, Mail, ArrowRight } from "lucide-react";
import "./Country.css";
import Navbar from "./Navbar";

export default function UserDetailsForm() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    navigate("/quest", {
      state: {
        userName: data.name,
        userEmail: data.email,
        userCountry: data.country,
      },
    });
  };

  return (
    <div className="user-details-page">
      <Navbar />
      <div className="form-container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="form-card"
        >
          <h2 className="form-title">Tell Us About Yourself</h2>
          <p className="form-subtitle">
            Let's start your carbon footprint journey!
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <div className="form-field">
              <label htmlFor="name">
                <User size={18} />
                <span>Name</span>
              </label>
              <input
                id="name"
                type="text"
                placeholder="Enter your name"
                {...register("name", { required: "Name is required" })}
              />
              {errors.name && <p className="error">{errors.name.message}</p>}
            </div>

            <div className="form-field">
              <label htmlFor="country">
                <MapPin size={18} />
                <span>Country</span>
              </label>
              <select
                id="country"
                {...register("country", { required: "Country is required" })}
              >
                <option value="">Select your country</option>
                <option value="us">United States</option>
                <option value="in">India</option>
                <option value="uk">United Kingdom</option>
                <option value="au">Australia</option>
                <option value="ca">Canada</option>
              </select>
              {errors.country && (
                <p className="error">{errors.country.message}</p>
              )}
            </div>

            <div className="form-field">
              <label htmlFor="email">
                <Mail size={18} />
                <span>Email</span>
              </label>
              <input
                id="email"
                type="email"
                placeholder="Enter your email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && <p className="error">{errors.email.message}</p>}
            </div>

            <motion.button
              type="submit"
              className="submit-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Start Assessment
              <ArrowRight size={18} />
            </motion.button>
          </form>
        </motion.div>
      </div>
    </div>
  );
}
