import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import HomePage from "./components/HomePage";
import Country from "./components/Country";
import Question1 from "./components/Question1";
import ResultsPage from "./components/Result";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="Home-Page" />} />
        <Route path="/Home-Page" element={<HomePage />} />
        <Route path="/country" element={<Country />} />
        <Route path="/quest" element={<Question1 />} />
        <Route path="/results" element={<ResultsPage />} />
      </Routes>
    </Router>
  );
}

export default App;
