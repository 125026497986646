import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from "recharts";
import { GiShop } from "react-icons/gi";
import {
  Home,
  Zap,
  Car,
  Utensils,
  Smartphone,
  Recycle,
  ShoppingBag,
  Leaf,
  Info,
  TreePine,
  Globe2,
} from "lucide-react";
import Navbar from "./Navbar";
import "./Result.css";
import { useNavigate } from "react-router-dom";

const COLORS = [
  "#FF6B6B", // Household
  "#4ECDC4", // Energy
  "#45B7D1", // Travel
  "#FFA07A", // Food
  "#98D8C8", // Devices
  "#F7DC6F", // Waste
  "#BB8FCE", // Shopping
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={fill}
        className="chart-center-text"
      >
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
        className="chart-label"
      >{`${value.toFixed(2)} kg CO2`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
        className="chart-percent"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const CarbonFootprintResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { answers, userName } = location.state || {};

  const [carbonFootprint, setCarbonFootprint] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [footprintLevel, setFootprintLevel] = useState("");

  const calculateCarbonFootprint = () => {
    const householdFootprint = (answers[1] || 1) * 1000;
    const energyFootprint = (answers[3] || 0) * 0.92;
    const travelFootprint = (answers[6] || 0) * 0.2;
    const foodFootprint = answers[7] === "Daily" ? 100 : 50;
    const deviceFootprint = (answers[9] || 0) * 0.5;
    const wasteFootprint = (answers[12] || 0) * 0.3;
    const shoppingFootprint = answers[13] === "Monthly" ? 200 : 100;

    const total =
      householdFootprint +
      energyFootprint +
      travelFootprint +
      foodFootprint +
      deviceFootprint +
      wasteFootprint +
      shoppingFootprint;

    // Determine footprint level
    if (total < 5000) {
      setFootprintLevel("Low");
    } else if (total < 10000) {
      setFootprintLevel("Moderate");
    } else {
      setFootprintLevel("High");
    }

    setChartData([
      { name: "Household", value: householdFootprint },
      { name: "Energy", value: energyFootprint },
      { name: "Travel", value: travelFootprint },
      { name: "Food", value: foodFootprint },
      { name: "Devices", value: deviceFootprint },
      { name: "Waste", value: wasteFootprint },
      { name: "Shopping", value: shoppingFootprint },
    ]);

    return total;
  };

  useEffect(() => {
    setCarbonFootprint(calculateCarbonFootprint());
  }, [answers]);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const handleCalculate = () => {
    navigate("/");
  };

  const CategoryIcon = ({ category }) => {
    switch (category) {
      case "Household":
        return <Home className="category-icon" />;
      case "Energy":
        return <Zap className="category-icon" />;
      case "Travel":
        return <Car className="category-icon" />;
      case "Food":
        return <Utensils className="category-icon" />;
      case "Devices":
        return <Smartphone className="category-icon" />;
      case "Waste":
        return <Recycle className="category-icon" />;
      case "Shopping":
        return <ShoppingBag className="category-icon" />;
      default:
        return null;
    }
  };

  const getRecommendations = () => {
    return [
      {
        category: "Household",
        icon: <Home className="recommendation-icon" />,
        tips: [
          "Install LED light bulbs throughout your home",
          "Use a programmable thermostat",
          "Improve home insulation",
        ],
      },
      {
        category: "Energy",
        icon: <Zap className="recommendation-icon" />,
        tips: [
          "Switch to renewable energy sources",
          "Unplug devices when not in use",
          "Use energy-efficient appliances",
        ],
      },
      {
        category: "Travel",
        icon: <Car className="recommendation-icon" />,
        tips: [
          "Use public transportation when possible",
          "Consider carpooling",
          "Maintain proper tire pressure",
        ],
      },
      {
        category: "Shopping",
        icon: <GiShop className="recommendation-icon" />,
        tips: [
          "Opt for reusable shopping bags",
          "Shop in bulk to reduce packaging waste",
          "Buy products with minimal or eco-friendly packaging",
          "Support local businesses to reduce carbon footprint",
        ],
      },
    ];
  };

  const getEnvironmentalImpact = () => {
    const treesNeeded = Math.ceil(carbonFootprint / 22); // Average tree absorbs 22kg CO2 per year
    return {
      trees: treesNeeded,
      equivalent: (carbonFootprint / 1000).toFixed(1), // Convert to metric tons
    };
  };

  return (
    <div className="results-page">
      <Navbar />
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="results-container"
      >
        <h1 className="results-title">
          {userName
            ? `${userName}'s Carbon Footprint Results`
            : "Your Carbon Footprint Result"}
        </h1>

        {/* Impact Summary Section */}
        <div className="impact-summary">
          <div className="impact-card">
            <Globe2 className="impact-icon" />
            <h3>Footprint Level</h3>
            <p className={`impact-level ${footprintLevel.toLowerCase()}`}>
              {footprintLevel}
            </p>
            <p className="impact-description">
              Your carbon footprint is considered {footprintLevel.toLowerCase()}{" "}
              compared to the average.
            </p>
          </div>
          <div className="impact-card">
            <TreePine className="impact-icon" />
            <h3>Environmental Impact</h3>
            <p className="impact-number">{getEnvironmentalImpact().trees}</p>
            <p className="impact-description">
              Trees needed per year to offset your carbon footprint
            </p>
          </div>
          <div className="impact-card">
            <Info className="impact-icon" />
            <h3>CO₂ Equivalent</h3>
            <p className="impact-number">
              {getEnvironmentalImpact().equivalent}
            </p>
            <p className="impact-description">Metric tons of CO₂ per year</p>
          </div>
        </div>

        {/* Existing Results Summary Section */}
        <div className="results-summary">
          <div className="total-footprint">
            <Leaf className="leaf-icon" />
            <h2>Total Carbon Footprint</h2>
            <p>{carbonFootprint.toFixed(2)} kg CO2</p>
          </div>
          <div className="chart-container">
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                >
                  {chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Category Breakdown Section */}
        <div className="category-breakdown">
          {chartData.map((category, index) => (
            <motion.div
              key={category.name}
              className="category-item"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <CategoryIcon category={category.name} />
              <div className="category-details">
                <h3>{category.name}</h3>
                <p>{category.value.toFixed(2)} kg CO2</p>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Recommendations Section */}
        <div className="recommendations-section">
          <h2 className="section-title">
            Recommendations to Reduce Your Footprint
          </h2>
          <div className="recommendations-grid">
            {getRecommendations().map((rec, index) => (
              <motion.div
                key={rec.category}
                className="recommendation-card"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="recommendation-header">
                  {rec.icon}
                  <h3>{rec.category}</h3>
                </div>
                <ul className="recommendation-tips">
                  {rec.tips.map((tip, i) => (
                    <li key={i}>{tip}</li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Action Buttons */}
        <div className="action-buttons">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => window.location.reload()}
            className="calculate-again-btn"
            onClick={handleCalculate}
          >
            Calculate Again
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
};

export default CarbonFootprintResults;
